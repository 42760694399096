<template>
  <div class='page-box'>
    <div class='btn-box'>
      <a-button type='primary' @click='addRecord'>添加等级</a-button>
    </div>
    <a-table :loading='loading' :rowKey="record => record.levelId" :columns='columns' :data-source="tabldeData" :pagination="false" bordered>
      <template slot='state' slot-scope="text, record">
        <a-switch :checked='!!!record.state' :loading='levelId===record.levelId' @change='changeState($event,record)' checked-children="正常" un-checked-children="停用"></a-switch>
      </template>
      <div class="table-operations" slot="operation" slot-scope="text, record">
        <a-button type="link" @click='editRecord(record)'>编辑</a-button>
        <a-popconfirm placement="topRight" title="确定要删除？" ok-text="确定" cancel-text="取消" @confirm="()=>confirmDelete(record)">
          <a-button type="link">删除</a-button>
        </a-popconfirm>
      </div>
    </a-table>
    <a-modal v-model='showModal' :width="800" :title='isAdd?"添加等级":"编辑等级"' :confirm-loading='confirmLoading' @ok='handleSubmit' @cancel='handelCancel'>
      <a-form-model ref='formRef' :model="form" :rules="rules" :wrapper-col="{span:20}" :label-col="{span:4}">
        <a-form-model-item label='等级名称' prop='levelName'>
          <a-input v-model="form.levelName" placeholder='建议使用汉字'></a-input>
        </a-form-model-item>
        <a-form-model-item label='等级编号' prop='levelNum'>
          <a-input v-model="form.levelNum" placeholder='请输入'></a-input>
        </a-form-model-item>
        <a-form-model-item label='晋升顺序' prop='promotionSort' extra="数值越大等级越高,有会员入住之后，严禁修改,最大值50">
          <a-input-number :precision="0" :min='0' :max='50' style='width:100%;' :step="1" v-model="form.promotionSort" placeholder='请输入'></a-input-number>
        </a-form-model-item>
        <a-form-model-item v-if="form.promotionSort!==1" label='上个等级数量' :min='0' prop='upFrontNum' extra="需要多少个前一等级的下级才能晋升">
          <a-input-number :precision="0" style='width:100%;' :step="1" v-model="form.upFrontNum" placeholder='请输入'></a-input-number>
        </a-form-model-item>
        <a-form-model-item v-if="form.promotionSort!==1" label='是否直属' :min='0' prop='upFrontFlag' extra="上个等级数量是直属下级还是所有下级">
          <a-radio-group v-model='form.upFrontFlag'>
            <a-radio :value="true">
              是
            </a-radio>
            <a-radio :value="false">
              否
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="form.promotionSort!==1" label='创客数量' :min='0' prop='ckNum' extra="满足上个等级数量，团队还需要多少个创客才能晋升">
          <a-input-number :precision="0" style='width:100%;' :step="1" v-model="form.ckNum" placeholder='请输入'></a-input-number>
        </a-form-model-item>
        <a-form-model-item label='升级描述' prop='upGradeDesc'>
          <div class="rich-text" ref="richTextRef"></div>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { checkInteger } from '@/utils/validator'
import { agent } from '@/api'
import E from 'wangeditor'
export default {
  name: 'PageMemberLevelManagement',
  data () {
    return {
      levelId: '',
      isAdd: false,
      form: {
        relationType: 1,
        upFrontFlag: false,
      },
      editor: null,
      showModal: false,
      confirmLoading: false,
      loading: false,
      editorContent: '',
      rules: {
        levelName: [{ required: true, message: '请输入', trigger: 'change' }],
        levelNum: [{ required: true, message: '请输入', trigger: 'change' }],
        promotionSort: [
          { required: true, message: '请输入', trigger: 'change' },
          { validator: checkInteger, trigger: 'change' },
        ],
        upFrontNum: [{ required: true, message: '请输入', trigger: 'change' }],
        upFrontFlag: [{ required: true, message: '请选择', trigger: 'change' }],
        ckNum: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      columns: [
        {
          title: '等级名称',
          dataIndex: 'levelName',
          scopedSlots: { customRender: 'levelName' },
        },
        {
          title: '等级编号',
          dataIndex: 'levelNum',
          scopedSlots: { customRender: 'levelNum' },
        },
        {
          title: '晋升顺序',
          dataIndex: 'promotionSort',
          scopedSlots: { customRender: 'promotionSort' },
        },
        {
          title: '状态',
          dataIndex: 'state',
          scopedSlots: { customRender: 'state' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      tabldeData: [],
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    createEditor () {
      this.editor = new E(this.$refs.richTextRef)
      this.editor.customConfig = {
        onchange: (html) => {
          this.form.upGradeDesc = html
        },
        menus: ['head', 'bold', 'fontSize', 'justify', 'undo', 'redo'],
        showLinkImg: false,
      }
      this.editor.create()
    },
    async getData () {
      this.loading = true
      const { data, msg, code } = await agent.getTeamLevelList({ relationType: 1 })
      this.loading = false
      if (code === '00000') {
        this.tabldeData = data
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    async confirmDelete (record) {
      const { msg, code } = await agent.deleteTeamLevel({
        levelIds: record.levelId,
      })
      if (code === '00000') {
        this.$message.success('操作成功')
        this.getData()
      } else {
        this.$message.error(msg || '操作失败')
      }
    },
    handleSubmit () {
      this.$refs.formRef.validate((v) => {
        if (v) {
          this.submit()
        }
      })
    },
    async submit () {
      this.confirmLoading = true
      this.form.relationType = 1
      const { msg, code } = await agent.saveTeamLevel(this.form)
      this.confirmLoading = false
      if (code === '00000') {
        this.$message.success('操作成功')
        this.handelCancel()
        this.showModal = false
        this.getData()
      } else {
        this.$message.error(msg || '操作失败')
      }
    },
    handelCancel () {
      this.$refs.formRef.resetFields()
      this.form = {}
      if (this.editor) this.editor.txt.html('')
    },
    async addRecord () {
      this.isAdd = true
      this.showModal = true
      this.form = {
        relationType: 1,
        upFrontNum: 0,
        upFrontFlag: false,
        ckNum: 0,
      }
      if (!this.editor) {
        await this.$nextTick()
        this.createEditor()
      }
    },
    async editRecord (record) {
      this.isAdd = false
      this.form = Object.assign({}, record)
      this.showModal = true
      if (!this.editor) {
        await this.$nextTick()
        this.createEditor()
      }
      this.form.upGradeDesc = record.upGradeDesc
      if (this.editor) this.editor.txt.html(record.upGradeDesc)
    },
    async changeState (e, record) {
      this.levelId = record.levelId
      let state = 0
      if (e) {
        state = 0
      } else {
        state = 1
      }
      this.confirmLoading = true
      const { msg, code } = await agent.saveTeamLevel({
        levelId: record.levelId,
        state,
      })
      this.confirmLoading = false
      this.levelId = ''
      if (code === '00000') {
        this.$message.success('操作成功')
        this.getData()
      } else {
        this.$message.error(msg || '操作失败')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.btn-box {
  margin: 12px 0;
}
</style>
